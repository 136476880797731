import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { HISTORY_TOKEN } from '@cmi/app';
import {
  AppErrorHandler,
  FileHttpService,
  HeaderComponentService,
  PdfViewerNotifyService,
  SlideoutService
} from '@cmi/shared/services';
import { APP_FEATURE_KEY, appReducer, AppEffects, AppHttpService } from '@cmi/store/app';
import { MODAL_FEATURE_KEY, modalReducer } from '@cmi/store/modal';
import { PROCESS_FEATURE_KEY, ProcessEffects, processReducer } from '@cmi/store/process';
import { TOAST_FEATURE_KEY, toastReducer } from '@cmi/store/toast';
import { USER_FEATURE_KEY, userReducer } from '@cmi/store/user';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { provideQuillConfig } from 'ngx-quill';
import { AuthConfig, WreAuthlibModule } from 'wre-authlib';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

const internalAuthConfig = {
  protectedResourceMap: environment.protectedResourceMap,
  customScopes: environment.customScopes,
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  homePageUrl: '/dashboard',
  unauthorisedPageUrl: '/unauthorized',
  applicationName: environment.applicationName,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl
} as AuthConfig;

const externalAuthConfig = {
  protectedResourceMap: environment.b2cProtectedResourceMap,
  customScopes: environment.b2cCustomScopes,
  auth: {
    clientId: environment.b2cClientId,
    authority: environment.b2cAuthority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    knownAuthorities: environment.b2cKnownAuthorities
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  homePageUrl: '/dashboard',
  unauthorisedPageUrl: '/unauthorized',
  applicationName: environment.applicationName,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
  resetPasswordAuthority: environment.b2cResetPasswordAuthority
} as AuthConfig;

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes),
    provideStore({
      [MODAL_FEATURE_KEY]: modalReducer,
      [PROCESS_FEATURE_KEY]: processReducer,
      [USER_FEATURE_KEY]: userReducer,
      [APP_FEATURE_KEY]: appReducer,
      [TOAST_FEATURE_KEY]: toastReducer
    }),
    provideEffects([AppEffects, ProcessEffects]),
    provideQuillConfig({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }]
        ],
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: () => true
            }
          }
        }
      }
    }),
    importProvidersFrom(
      StoreDevtoolsModule.instrument({
        name: 'ngRx Dev Tools',
        maxAge: 100,
        logOnly: environment.production
      }),
      WreAuthlibModule.forRoot(
        internalAuthConfig,
        externalAuthConfig,
        environment.loggingEnabled,
        environment.logLevel
      )
    ),
    AppHttpService,
    FileHttpService,
    HeaderComponentService,
    PdfViewerNotifyService,
    SlideoutService,
    { provide: HISTORY_TOKEN, useValue: window.history },
    { provide: ErrorHandler, useClass: AppErrorHandler }
  ]
};
